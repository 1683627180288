import React from "react";
import Logo from "../Assets/images/Logo.jpg";

const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
      <img
        src={Logo} // Replace with the path to your logo
        alt="Logo"
        className="coming-soon-logo"
      />
      <h1 id="#text-20-600-15-Poppins" style={{color: '#B07E2C'}}>Coming Soon.....!</h1>
    </div>
  );
};

export default ComingSoon;
