import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import WifiCalling3Icon from "@mui/icons-material/WifiCalling3";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LoginModal from "./Loginmodal";
import RegisterModal from "./RegisterNow";

function Navbar1() {
  const navigate = useNavigate();
  const [openLogin, setOpenLogin] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));
  console.log(user);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleNavigate = () => {
    navigate("/Doctorspage");
  };

  const handleOpenLogin = () => {
    setOpenLogin(true);
    setOpenRegister(false);
  };

  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  const handleOpenRegister = () => {
    setOpenRegister(true);
    setOpenLogin(false);
  };

  const handleCloseRegister = () => {
    setOpenRegister(false);
  };

  const handleProfile = () => {
    navigate("/Profile");
  };

  const handleLogout = () => {
    // Clear localStorage on logout
    navigate("/");
    localStorage.clear();
    setIsLoggedIn(false); // Update state to reflect logout
    window.location.reload();
  };

  // Open the profile dropdown
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Close the profile dropdown
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "#e0e0e0", padding: "10px" }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={2}
          padding={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={12} md={2}>
            {/* Phone Number */}
            <Box
              display={"flex"}
              gap={1}
              justifyContent="center"
              alignItems="center"
            >
              <WifiCalling3Icon sx={{ color: "#B07E2C" }} />
              <Typography sx={{ color: "#171859", fontWeight: "600" }}>
                +974 12 34 56 78
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            display={"flex"}
            justifyContent="center"
            alignItems="center"
          >
            {/* Address */}
            <Typography
              sx={{ color: "#171859", fontWeight: "600" }}
              textAlign="center"
            >
              Address: P.O. Box: 123456, C Ring Road, Doha - Qatar
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            display={"flex"}
            justifyContent="center"
            alignItems="center"
          >
            {/* Appointment Button */}
            <Button
              variant="contained"
              sx={{ backgroundColor: "#B07E2C", fontWeight: "600" }}
              onClick={handleNavigate}
            >
              MAKE APPOINTMENT
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            {/* Profile/Logout Section */}
            <Box display={"flex"} gap={2} justifyContent={"flex-end"} mr={5}>
              {!isLoggedIn ? (
                <>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#171859", fontWeight: "600" }}
                    onClick={handleOpenLogin}
                  >
                    Login
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#171859", fontWeight: "600" }}
                    onClick={handleOpenRegister}
                  >
                    Register Now
                  </Button>
                </>
              ) : (
                <>
                  <IconButton
                    sx={{ color: "#171859", gap: 1 }}
                    onClick={handleMenuOpen}
                  >
                    <AccountCircleIcon sx={{ fontSize: 30 }} />
                    <Typography sx={{ color: "#171859", fontWeight: "600" }}>
                      Hi, {user?.firstname || "User"}
                    </Typography>
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleProfile}>Profile</MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <LoginModal open={openLogin} handleClose={handleCloseLogin} />
      <RegisterModal open={openRegister} handleClose={handleCloseRegister} />
    </AppBar>
  );
}

export default Navbar1;
