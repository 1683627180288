import React from "react";
import { Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Ologo from "./../Assets/images/O_logo.jpg";
import cardio from "./../Assets/images/Departments/Cardiology.png";
import urology from "./../Assets/images/Departments/Urology.png";
import pulmonology from "./../Assets/images/Departments/Pulmonology.png";
import ENT from "./../Assets/images/Departments/ENT-&-Audiology.png";
import obstetrics from "./../Assets/images/Departments/Obstetrics-&-Gynecology.png";
import pediatric from "./../Assets/images/Departments/Pediatric.png";
import internal from "./../Assets/images/Departments/Internal-Medicine.png";
import general from "./../Assets/images/Departments/General-Medicine.png";
import neurology from "./../Assets/images/Departments/Neurology.png";
import "./../Assets/css/pagescss/Departmentpage.css";

const departments = [
  {
    image: cardio,
    name: "Cardiology",
    description:
      "The cardiology department specializes in diagnosing, treating, and preventing heart-related diseases and conditions. It offers various services, including cardiac evaluations and treatment of coronary artery disease, arrhythmias, heart failure, and hypertension.",
  },
  {
    image: urology,
    name: "Urology",
    description:
      "The Urology Department specializes in diagnosing and treating conditions related to the urinary tract and male reproductive system. It offers comprehensive services for both men and women, addressing issues such as urinary incontinence, kidney stones, urinary tract infections, and prostate problems.",
  },
  {
    image: pulmonology,
    name: "Pulmonology",
    description:
      "The pulmonology department specializes in diagnosing and treating diseases related to the respiratory system, including the lungs and airways. It offers care for conditions such as asthma, COPD, pneumonia, lung cancer, and sleep apnea.",
  },
  {
    image: ENT,
    name: "ENT & Audiology",
    description:
      "Our ENT and Audiology Department specializes in comprehensive care for ear, nose, throat, and hearing disorders. Our team of experienced ENT surgeons and audiologists provide advanced diagnostic services, surgical treatments, and hearing solutions for patients of all ages.",
  },
  {
    image: obstetrics,
    name: "Obstetrics & Gynecology",
    description:
      "The Obstetrics and Gynecology department provides comprehensive care for women at all stages of life, from routine check-ups to advanced obstetric care, focusing on personalized care and the latest diagnostic and treatment technologies.",
  },
  {
    image: pediatric,
    name: "Pediatric",
    description:
      "The Pediatric Department offers comprehensive care for infants, children, and adolescents. Our team of pediatricians provides preventive care, vaccinations, check-ups, and treatment for acute and chronic illnesses in a child-friendly environment.",
  },
  {
    image: internal,
    name: "Internal Medicine",
    description:
      "The Internal Medicine department provides comprehensive care for adults, focusing on prevention, diagnosis, and treatment of various illnesses and chronic conditions, emphasizing patient education and wellness.",
  },
  {
    image: general,
    name: "General Surgery",
    description:
      "The General Surgery department provides surgical care, specializing in procedures like abdominal, hernia, and gastrointestinal surgeries with a focus on patient safety and minimally invasive techniques for faster recovery.",
  },
  {
    image: neurology,
    name: "Neurology",
    description:
      "Our Neurology department specializes in diagnosing and treating neurological disorders, including stroke, epilepsy, multiple sclerosis, and headaches, with personalized treatment and rehabilitation services.",
  },
];

function Departmentspage() {
  return (
    <>
      <div className="Homepage">
        <div className="Homebanner-container">
          <div className="Departmentbanner"></div>
          <div className="border"></div>
        </div>
      </div>
      <div className="department-section">
        <Grid container xl={12} display={"flex"} justifyContent={"center"} mt={8}>
          <Grid display={"flex"} alignItems={"center"} sx={{ color: "#171859" }}>
            <img src={Ologo} alt="o-logo" width={"40px"} height={"40px"} />
            <Typography id="text-30-600-15-Poppins">UR </Typography>
            <Typography id="text-30-600-15-Poppins" color="#B07E2C" ml={1}>
              DEPARTMENTS{" "}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={4} padding={"80px"} sx={{ color: "#171859" }}>
          {departments.map((department, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1}>
                <img src={department.image} alt={`${department.name} logo`} width={"40px"} height={"40px"} />
                <Typography id="text-20-600-15-Poppins">{department.name}</Typography>
              </Box>
              <Typography id="text-15-Poppins" textAlign={"justify"} mt={2}>
                {department.description}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
}

export default Departmentspage;