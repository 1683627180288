import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import profile from "./../Assets/images/profile.jpg";
import EditIcon from "@mui/icons-material/Edit";

const Profile = () => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");

  const [isEditing, setIsEditing] = useState(false);
  const [updatedUser, setUpdatedUser] = useState(user);
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user") || "{}");
    if (storedUser && storedUser._id) {
      setUpdatedUser(storedUser);
    } else {
      const fetchUserData = async () => {
        try {
          const response = await fetch(
            `http://localhost:3001/registration/${storedUser._id}`
          );
          const data = await response.json();
          setUpdatedUser(data);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };
      fetchUserData();
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedUser({ ...updatedUser, [name]: value });
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const validateFields = () => {
    const newErrors = {};
    if (!updatedUser.firstname) newErrors.firstname = "First name is required";
    if (!updatedUser.lastname) newErrors.lastname = "Last name is required";
    if (!updatedUser.email) newErrors.email = "Email is required";
    if (!updatedUser.mobile) newErrors.mobile = "Mobile number is required";
    if (!updatedUser.gender) newErrors.gender = "Gender is required";
    if (!updatedUser.dateOfBirth) newErrors.dateOfBirth = "DateOfBirth is required";
    if (!updatedUser.height) newErrors.height = "Height is required";
    if (!updatedUser.weight) newErrors.weight = "Weight is required";
    if (!updatedUser.bloodgroup) newErrors.bloodgroup = "Blood-group is required";
    if (!updatedUser.address) newErrors.address = "Address is required";
    if (!updatedUser.city) newErrors.city = "City is required";
    if (!updatedUser.country) newErrors.country = "Country is required";
    if (!updatedUser.postalCode)
      newErrors.postalCode = "Postal code is required";
    return newErrors;
  };

  const handleUpdate = async () => {
    const newErrors = validateFields();
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        const response = await fetch(
          `http://localhost:3001/registration/edit/${user._id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedUser),
          }
        );

        const data = await response.json();
        if (response.ok) {
          setUpdatedUser(data);
          localStorage.setItem("user", JSON.stringify(data));
          setSuccessMessage("Patient updated successfully");
        } else {
          alert("Error updating Patient: " + data.message);
        }
      } catch (error) {
        console.error("Error updating Patient:", error);
        alert("An error occurred while updating Patient information");
      }
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 5 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Card sx={{ boxShadow: 3 }}>
            <CardContent>
              <Grid container justifyContent="center">
                <Grid item>
                  <img
                    alt="Profile"
                    src={profile}
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                    }}
                  />
                </Grid>
              </Grid>
              <Typography variant="body2" sx={{ mt: 2 }}>
                Name: {updatedUser.firstname} {updatedUser.lastname}
              </Typography>
              <Typography variant="body2" sx={{ mt: 2 }}>
                ID: {updatedUser.id}
              </Typography>
              <Typography variant="body2" sx={{ mt: 2 }}>
                Role: {updatedUser.role}
              </Typography>
              <Typography variant="body2" sx={{ mt: 2 }}>
                Mobile No: {updatedUser.mobile}
              </Typography>
              <Typography variant="body2" sx={{ mt: 2 }}>
                Email: {updatedUser.email}
              </Typography>
              <Typography variant="body2" sx={{ mt: 2 }}>
                Gender: {updatedUser.gender}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ boxShadow: 3 }}>
            <Grid item xs={12} display="flex" justifyContent="space-between">
              <CardHeader
                title="My Account"
                sx={{ color: "#b07e2c", fontWeight: 800 }}
              />
              <IconButton sx={{ color: "#171859" }} onClick={handleEditClick}>
                <EditIcon />
              </IconButton>
            </Grid>

            <CardContent>
              <Typography
                variant="h6"
                sx={{ color: "#b07e2c", fontWeight: 800 }}
                gutterBottom
              >
                User information
              </Typography>
              <Grid container spacing={4} mt={2}>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1">First Name</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="firstname"
                    value={updatedUser.firstname || ""}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    sx={{ mt: 2 }}
                    error={!!errors.firstname}
                    helperText={errors.firstname}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1">Last Name</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="lastname"
                    value={updatedUser.lastname || ""}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    sx={{ mt: 2 }}
                    error={!!errors.lastname}
                    helperText={errors.lastname}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1">Email</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="email"
                    value={updatedUser.email}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    sx={{ mt: 2 }}
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1">Mobile No</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="mobile"
                    value={updatedUser.mobile}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    sx={{ mt: 2 }}
                    error={!!errors.mobile}
                    helperText={errors.mobile}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1">Gender</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="gender"
                    value={updatedUser.gender}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    sx={{ mt: 2 }}
                    error={!!errors.gender}
                    helperText={errors.gender}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1">Date of birth</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="dateOfBirth"
                    type="date"
                    value={
                      updatedUser.dateOfBirth
                        ? new Date(updatedUser.dateOfBirth)
                            .toISOString()
                            .split("T")[0]
                        : ""
                    }
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    sx={{ mt: 2 }}
                    error={!!errors.dateOfBirth}
                    helperText={errors.dateOfBirth}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1">Height</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="height"
                    value={updatedUser.height}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    sx={{ mt: 2 }}
                    error={!!errors.height}
                    helperText={errors.height}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1">Weight</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="weight"
                    value={updatedUser.weight}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    sx={{ mt: 2 }}
                    error={!!errors.weight}
                    helperText={errors.weight}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1">Blood Group</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="bloodgroup"
                    value={updatedUser.bloodgroup}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    sx={{ mt: 2 }}
                    error={!!errors.bloodgroup}
                    helperText={errors.bloodgroup}
                  />
                </Grid>
              </Grid>
              <Typography
                variant="h6"
                sx={{ color: "#b07e2c", fontWeight: 800 }}
                mt={5}
              >
                Contact information
              </Typography>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                  <Typography variant="body1">Address</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="address"
                    value={updatedUser.address || ""}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    sx={{ mt: 2 }}
                    error={!!errors.address}
                    helperText={errors.address}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1">City</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="city"
                    value={updatedUser.city || ""}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    sx={{ mt: 2 }}
                    error={!!errors.city}
                    helperText={errors.city}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1">Country</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="country"
                    value={updatedUser.country || ""}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    sx={{ mt: 2 }}
                    error={!!errors.country}
                    helperText={errors.country}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1">Postal Code</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="postalCode"
                    value={updatedUser.postalCode || ""}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    sx={{ mt: 2 }}
                    error={!!errors.postalCode}
                    helperText={errors.postalCode}
                  />
                </Grid>
              </Grid>

              <Grid container justifyContent="flex-end" mt={4}>
                {isEditing && (
                  <Button
                    variant="contained"
                    onClick={handleUpdate}
                    sx={{ backgroundColor: "#b07e2c" }}
                  >
                    Save Changes
                  </Button>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {successMessage && (
        <Snackbar
          open
          autoHideDuration={6000}
          onClose={() => setSuccessMessage("")}
        >
          <Alert
            onClose={() => setSuccessMessage("")}
            severity="success"
            sx={{ width: "100%" }}
          >
            {successMessage}
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default Profile;
