import React, { useState } from "react";
import { Modal, Box, Button, TextField, Typography, Tabs, Tab, Link } from "@mui/material";
import axios from "axios"; // Assuming axios is being used here

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function LoginModal({ open, handleClose }) {
  const [value, setValue] = useState(0);
  const [credentials, setCredentials] = useState({});
  const [successMessage, setSuccessMessage] = useState(""); // To show success message

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (loginType, field, value) => {
    setCredentials((prev) => ({
      ...prev,
      [loginType]: {
        ...prev[loginType],
        [field]: value,
      },
    }));
  };

  const loginForms = {
    user: {
      fields: [
        { label: "Email", type: "email", name: "email" },
        { label: "Password", type: "password", name: "password" },
      ],
      buttonLabel: "Patient Login",
    },
    admin: {
      fields: [
        { label: "Admin ID", type: "text", name: "adminId" },
        { label: "Password", type: "password", name: "password" },
      ],
      buttonLabel: "Admin Login",
    },
    doctor: {
      fields: [
        { label: "Doctor ID", type: "text", name: "doctorId" },
        { label: "Password", type: "password", name: "password" },
      ],
      buttonLabel: "Doctor Login",
    },
  };

  const handleLogin = async (loginType) => {
    const loginData = {
      ...credentials[loginType],
      role: loginType,
    };

    try {
      const response = await axios.post("http://localhost:3001/registration/login", loginData);
      console.log(response.data);

      if (response.data.type === "success") {
        // Store the user credentials in localStorage
        localStorage.setItem('user', JSON.stringify(response.data.user)); // Store user info
        localStorage.setItem('token', response.data.token); // Store JWT token

        // Display success message
        setSuccessMessage(response.data.message);
        
        // Hide success message after 3 seconds
        setTimeout(() => setSuccessMessage(""), 3000);

        // Close the login modal
        handleClose();

        // Refresh the page after successful login
        window.location.reload();
      }
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const renderForm = (loginType) => {
    const { fields, buttonLabel } = loginForms[loginType];
    return (
      <>
        {fields.map((field, index) => (
          <TextField
            key={index}
            fullWidth
            label={field.label}
            variant="outlined"
            type={field.type}
            sx={{ mb: 2 }}
            value={credentials[loginType]?.[field.name] || ""}
            onChange={(e) => handleInputChange(loginType, field.name, e.target.value)}
          />
        ))}
        <Link
          href="#"
          sx={{ color: 'red', display: 'flex', mb: 2, textDecoration: 'none', justifyContent: 'flex-end' }}
          id="text-13-500-15-Poppins"
        >
          Forgot Password?
        </Link>
        <Button
          variant="contained"
          fullWidth
          id="text-18-500-15-Poppins"
          sx={{ backgroundColor: "#B07E2C", height: '50px', textTransform: 'capitalize' }}
          onClick={() => handleLogin(loginType)}
        >
          {buttonLabel}
        </Button>
      </>
    );
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Typography id="text-20-600-15-Poppins" variant="h5" component="h2" sx={{ color: "#B07E2C", fontWeight: "600", mb: 3 }} display={'flex'} justifyContent={'center'}>
          Login
        </Typography>

        {/* Tab Switching Section */}
        <Tabs value={value} onChange={handleChange} aria-label="login tabs">
          <Tab label="Patient Login" {...a11yProps(0)} id="text-13-600-15-Poppins" />
          <Tab label="Admin Login" {...a11yProps(1)} id="text-13-600-15-Poppins" />
          <Tab label="Doctor Login" {...a11yProps(2)} id="text-13-600-15-Poppins" />
        </Tabs>

        {/* Patient Login Form */}
        <TabPanel value={value} index={0}>
          {renderForm("user")}
        </TabPanel>

        {/* Admin Login Form */}
        <TabPanel value={value} index={1}>
          {renderForm("admin")}
        </TabPanel>

        {/* Doctor Login Form */}
        <TabPanel value={value} index={2}>
          {renderForm("doctor")}
        </TabPanel>

        {/* Display Success Message */}
        {successMessage && (
          <Typography sx={{ color: "green", fontWeight: "600", mt: 2 }}>
            {successMessage}
          </Typography>
        )}
      </Box>
    </Modal>
  );
}

export default LoginModal;