import React, { useState } from "react";
import {
  Modal,
  Box,
  Button,
  TextField,
  Typography,
  Tabs,
  Tab,
  Grid,
} from "@mui/material";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function RegisterModal({ open, handleClose }) {
  const [value, setValue] = useState(0);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    mobile: "",
    dateOfBirth: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setFormData({
      firstname: "",
      lastname: "",
      mobile: "",
      dateOfBirth: "",
      email: "",
      password: "",
      confirmPassword: "",
    });
    setErrors({});
    setSuccessMessage("");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const validateFields = () => {
    const { firstname, lastname, mobile, dateOfBirth, email, password, confirmPassword } = formData;
    const newErrors = {};

    if (!firstname) newErrors.firstname = "First name is required.";
    if (!lastname) newErrors.lastname = "Last name is required.";
    if (!mobile) newErrors.mobile = "Mobile number is required.";
    else if (!/^\d{10}$/.test(mobile)) newErrors.mobile = "Mobile number must be 10 digits.";
    if (!dateOfBirth) newErrors.dateOfBirth = "Date of birth is required.";
    if (!email) newErrors.email = "Email is required.";
    else if (!/\S+@\S+\.\S+/.test(email)) newErrors.email = "Invalid email format.";
    if (!password) newErrors.password = "Password is required.";
    else if (password.length < 6) newErrors.password = "Password must be at least 6 characters.";
    if (!confirmPassword) newErrors.confirmPassword = "Confirm password is required.";
    else if (password !== confirmPassword) newErrors.confirmPassword = "Passwords do not match.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateFields()) return;

    const { firstname, lastname, mobile, dateOfBirth, email, password, confirmPassword } = formData;

    try {
      const response = await fetch("http://localhost:3001/registration/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          role: "user",
          firstname,
          lastname,
          mobile,
          dateOfBirth,
          email,
          password,
          confirmPassword
        }),
      });

      const data = await response.json();
      if (response.ok) {
        setSuccessMessage(data.message);
        setFormData({
          firstname: "",
          lastname: "",
          mobile: "",
          dateOfBirth: "",
          email: "",
          password: "",
          confirmPassword: "",
        });
      } else {
        setErrors({ apiError: data.message });
      }
    } catch (error) {
      setErrors({ apiError: "An error occurred. Please try again." });
    }
  };

  const renderForm = () => {
    const fields = [
      { label: "First Name", type: "text", name: "firstname" },
      { label: "Last Name", type: "text", name: "lastname" },
      { label: "Mobile Number", type: "text", name: "mobile" },
      { label: "Date of Birth", type: "date", name: "dateOfBirth" },
      { label: "Email", type: "email", name: "email" },
      { label: "Password", type: "password", name: "password" },
      { label: "Confirm Password", type: "password", name: "confirmPassword" },
    ];

    return (
      <>
        <Grid container spacing={2}>
          {fields.map((field, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>
                {field.label}
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                type={field.type}
                name={field.name}
                value={formData[field.name]}
                onChange={handleInputChange}
                error={Boolean(errors[field.name])}
                helperText={errors[field.name]}
              />
            </Grid>
          ))}
        </Grid>
        {errors.apiError && <Typography color="error" sx={{ mt: 2 }}>{errors.apiError}</Typography>}
        {successMessage && <Typography color="success" sx={{ mt: 2 }}>{successMessage}</Typography>}
        <Button
          variant="contained"
          fullWidth
          onClick={handleSubmit}
          id="text-18-500-15-Poppins"
          sx={{
            backgroundColor: "#B07E2C",
            height: "50px",
            textTransform: "capitalize",
            mt: 2,
          }}
        >
          Register as User
        </Button>
      </>
    );
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Typography
          id="text-20-600-15-Poppins"
          variant="h5"
          component="h2"
          sx={{ color: "#B07E2C", fontWeight: "600", mb: 3 }}
          display={"flex"}
          justifyContent={"center"}
        >
          New Patient Registration
        </Typography>

        <Tabs value={value} onChange={handleChange} aria-label="register tabs">
          <Tab
            label="Patient Registration"
            {...a11yProps(0)}
            id="text-13-600-15-Poppins"
          />
        </Tabs>

        <TabPanel value={value} index={0}>
          {renderForm()}
        </TabPanel>
      </Box>
    </Modal>
  );
}

export default RegisterModal;