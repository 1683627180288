import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import logo from "../Assets/images/Logo.jpg";
import { NavLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { Drawer, IconButton } from "@mui/material";

const pages = [
  { name: "Departments", path: "/Departmentspage" },
  { name: "Doctors", path: "/Doctorspage" },
  { name: "Our Service Highlights", path: "/Facilitiespage" },
  { name: "Packages", path: "/Comingsoon" },
  { name: "Testimonials", path: "/Comingsoon" },
  { name: "Insurance", path: "/Comingsoon" },
  { name: "News & Events", path: "/Comingsoon" },
  { name: "Gallery", path: "/Comingsoon" },
  { name: "Contact", path: "/Comingsoon" },
];

function NavbarMain() {
  const [activePage, setActivePage] = React.useState(pages[0].name); // Default to the first page

  const [openDrawer, setOpenDrawer] = React.useState(false);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const handlePageClick = (pageName) => {
    setActivePage(pageName);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: "#FFFFFF" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ padding: "10px" }}>
          <Grid container alignItems="center">
            {/* Logo Section */}
            <Grid item xs={6} sm={2} display="flex" justifyContent="center">
              <img src={logo} alt="logo" width={"260px"} />
            </Grid>

            {/* Navbar Links */}
            <Grid item xs={6} sm={10}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: { xs: "none", md: "flex" }, // Hide links below 'md' and show them above
                    justifyContent: "space-around",
                    flexGrow: 1,
                  }}
                >
                  {pages.map((page) => (
                    <Button
                      key={page.name}
                      id="text-13-600-15-Poppins"
                      sx={{
                        my: 2,
                        color: activePage === page.name ? "#B07E2C" : "#171859", // Active page styling
                        display: "block",
                      }}
                      onClick={() => handlePageClick(page.name)} // Set active page
                    >
                      <NavLink
                        to={page.path}
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                        }}
                      >
                        {page.name}
                      </NavLink>
                    </Button>
                  ))}
                </Box>

                {/* Hamburger Icon for mobile and tablet screens */}
                <IconButton
                  sx={{ display: { xs: "block", sm: "block", md: "none" } }} // Show for 'xs' and 'sm'
                  onClick={toggleDrawer}
                >
                  <MenuIcon sx={{ color: "#171859" }} />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>

      {/* Drawer for mobile and tablet screens */}
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer}
        sx={{
          display: { xs: "block", sm: "block", md: "none" }, // Drawer visible on 'xs' and 'sm'
        }}
      >
        <Box
          sx={{ width: 250, padding: 2 }}
          role="presentation"
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        >
          {pages.map((page) => (
            <Button
              key={page.name}
              sx={{
                my: 2,
                color: activePage === page.name ? "#B07E2C" : "#171859", // Active page styling
                display: "block",
                width: "100%",
              }}
              onClick={() => handlePageClick(page.name)} // Set active page
            >
              <NavLink
                to={page.path}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                {page.name}
              </NavLink>
            </Button>
          ))}
        </Box>
      </Drawer>
    </AppBar>
  );
}

export default NavbarMain;