import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useLocation } from "react-router-dom";
import axios from "axios";

const AppointmentForm = () => {
  const location = useLocation();
  const doctorData = location.state?.doctor || {};
  const loggedInUser = JSON.parse(localStorage.getItem("user"));

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    department: doctorData.specialization || "",
    doctor: doctorData.name || "",
    date: null,
    timeSlot: "",
  });

  const timeSlots = [];
  for (let i = 8; i < 18; i += 0.5) {
    const hour = Math.floor(i);
    const minutes = i % 1 === 0 ? "00" : "30";
    const time = `${hour.toString().padStart(2, "0")}:${minutes}`;
    timeSlots.push(time);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleTimeSlotSelect = (slot) => {
    setFormData((prev) => ({ ...prev, timeSlot: slot }));
  };

  const handleDateChange = (date) => {
    setFormData((prev) => ({ ...prev, date }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!loggedInUser) {
      console.error("User is not logged in");
      return;
    }

    const dataToSend = {
      userId: loggedInUser._id, // Include logged-in user's ID
      firstname: formData.firstname,
      lastname: formData.lastname,
      mobilenumber: formData.phone,
      doctor: formData.doctor,
      department: formData.department,
      date: formData.date ? formData.date.format("YYYY-MM-DD") : null,
      time: formData.timeSlot,
    };

    try {
      const response = await axios.post(
        "http://localhost:3001/appointment/create",
        dataToSend
      );
      console.log("Response:", response.data);
      // Optionally show a success message or redirect to another page
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="First Name"
              name="firstname"
              fullWidth
              value={formData.firstname}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Last Name"
              name="lastname"
              fullWidth
              value={formData.lastname}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Phone Number"
              name="phone"
              fullWidth
              value={formData.phone}
              onChange={handleChange}
              required
              inputProps={{ type: "tel" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Department"
              name="department"
              fullWidth
              value={formData.department}
              onChange={handleChange}
              disabled
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Doctor"
              name="doctor"
              fullWidth
              value={formData.doctor}
              onChange={handleChange}
              disabled
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
              <DatePicker
                label="Appointment Date"
                value={formData.date}
                onChange={handleDateChange}
                renderInput={(params) => (
                  <TextField {...params} required fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel>Time Slot</InputLabel>
              <Select
                name="timeSlot"
                value={formData.timeSlot}
                onChange={handleChange}
                renderValue={(selected) => selected || "Select Time Slot"}
                MenuProps={{
                  PaperProps: {
                    style: {
                      width: "50%", // Set dropdown width to 50% of the screen
                    },
                  },
                }}
              >
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, p: 1 }}>
                  {timeSlots.map((slot) => (
                    <Box key={slot} sx={{ flex: "1 1 calc(16.66% - 8px)" }}>
                      <Button
                        variant={
                          formData.timeSlot === slot ? "contained" : "outlined"
                        }
                        color="primary"
                        onClick={() => handleTimeSlotSelect(slot)}
                        fullWidth
                      >
                        {slot}
                      </Button>
                    </Box>
                  ))}
                </Box>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Book Appointment
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default AppointmentForm;
