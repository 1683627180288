import React, { useState } from "react";
import { Typography, Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import Ologo from "./../Assets/images/O_logo.jpg";
import doctor1 from "./../Assets/images/Doctor1_with-background-0000.jpg";
import doctor2 from "./../Assets/images/Doctor2_with-background-0000.jpg";
import doctor3 from "./../Assets/images/Doctor3_with-background-0000.jpg";
import AddIcon from "@mui/icons-material/Add";
import LoginModal from "./../Common/Loginmodal";
import "./../Assets/css/pagescss/Doctorspage.css";

const doctorsData = [
  {
    id: 1,
    name: "Dr. Johnes",
    specialization: "General Medicine",
    image: doctor1,
  },
  {
    id: 2,
    name: "Dr. Smitha",
    specialization: "OB & GYNECOLOGY",
    image: doctor2,
  },
  {
    id: 3,
    name: "Dr. Rachel",
    specialization: "DERMATOLOGY",
    image: doctor3,
  },
];

const Doctorspage = () => {
  const navigate = useNavigate(); // Initialize navigate
  const [loginModalOpen, setLoginModalOpen] = useState(false); // State to manage login modal visibility
  const user = JSON.parse(localStorage.getItem("user")); // Retrieve user info from localStorage

  const handleBookAppointment = (doctor) => {
    if (!user) {
      // If user is not logged in, open the login modal
      setLoginModalOpen(true);
    } else {
      // If user is logged in, navigate to the appointment form
      navigate("/Appoinmentform", { state: { doctor } });
    }
  };

  return (
    <>
      <div className="Homepage">
        <div className="Homebanner-container">
          <div className="Doctorbanner"></div>
          <div className="border"></div>
        </div>
      </div>
      <div className="doctor-section">
        <Grid
          container
          xl={12}
          display={"flex"}
          justifyContent={"center"}
          mt={8}
        >
          <Grid
            display={"flex"}
            alignItems={"center"}
            sx={{ color: "#171859" }}
          >
            <img src={Ologo} alt="o-logo" width={"40px"} height={"40px"} />
            <Typography id="text-30-600-15-Poppins">UR</Typography>
            <Typography id="text-30-600-15-Poppins" color="#B07E2C" ml={1}>
              DOCTORS
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={8} padding={"80px"} sx={{ color: "#171859" }}>
          {doctorsData.map((doctor) => (
            <Grid key={doctor.id} size={4}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={1}
              >
                <img src={doctor.image} alt={doctor.name} />
              </Box>
              <Typography
                id="text-15-Poppins"
                display={"flex"}
                justifyContent={"center"}
                textAlign={"justify"}
                mt={2}
              >
                {doctor.name}
              </Typography>
              <Typography
                id="text-15-Poppins"
                display={"flex"}
                justifyContent={"center"}
                color="#B07E2C"
                ml={1}
              >
                {doctor.specialization}
              </Typography>
              <Box display="flex" justifyContent="center" mt={2}>
                {/* Book Now Button */}
                <Button
                  id="text-18-500-15-Poppins"
                  variant="contained"
                  color="primary"
                  onClick={() => handleBookAppointment(doctor)} // Pass specific doctor data
                  sx={{
                    borderRadius: "40px",
                    textTransform: "capitalize",
                    fontSize: "18px",
                    backgroundColor: "#B07E2C",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "15px 40px",
                    position: "relative",
                    transition:
                      "transform 0.3s ease, background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor: "#171859",
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  <AddIcon
                    sx={{
                      position: "absolute",
                      left: "10px",
                      fontSize: "20px",
                      outlineStyle: "solid",
                      borderRadius: "40px",
                    }}
                  />
                  Book Now
                </Button>
              </Box>
            </Grid>
          ))}
        </Grid>
      </div>

      {/* Login Modal */}
      <LoginModal
        open={loginModalOpen}
        handleClose={() => setLoginModalOpen(false)}
      />
    </>
  );
};

export default Doctorspage;